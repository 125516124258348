body {
    background: #282c3b;
    color: #FFF;
}

.modal-large {
    width: 90%;
    max-width: none !important;
}

.modal-content {
    background-color: #282c3b;
    border: 1px solid #282c3b;
}

.modal-header {
    border-bottom: 1px solid #14161e;
}

.btn-rounded {
    border-radius: 20px;
    box-shadow: 20px 10px 20px #80808026;
}

.btn-close-modal {
    position: absolute;
    bottom: 2%;
    right: 7%;
    padding: 5px 20px;
}


/**********************************************************************************
                            HEADER
***********************************************************************************/

#mainNav .navbar-nav .nav-item .nav-link {
    font-size: 1.2rem;
    letter-spacing: 0.0625em;
}


/**********************************************************************************
                            Body
***********************************************************************************/


/** Homepage Start **/

.about-description {
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin-top: 2rem;
}


/** Homepage End **/

.carousel-item img.d-block {
    height: 400px;
    width: auto;
}

.carousel-control-prev-icon {
    background-color: #282c3b;
    padding: 15px;
}

.carousel-caption {
    position: relative;
    padding-top: 5px;
    padding-bottom: 0px;
    color: #fff;
    border-radius: 20px;
    text-align: center;
    opacity: .6;
    left: 0%;
}

.carousel-caption h3 {
    font-size: 20px;
    margin-bottom: -17px;
    margin-top: 25px;
    font-weight: normal;
    letter-spacing: 1px;
}

.carousel-control-next-icon {
    background-color: #282c3b;
    padding: 15px;
}

.carousel-indicators li {
    width: 15px;
    height: 15px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #ffffff;
    opacity: 0.5;
    transition: opacity 0.6s ease;
    border-radius: 50px !important;
    border: 1px solid #FFF;
}

.carousel-indicators {
    bottom: -20px;
}

.carousel.slide {
    margin-bottom: 60px;
}

.portfolio-logo {
    width: 100px;
}


/** Life History **/

.timeline>li .timeline-image:hover {
    background: #e8396a;
    transition: .5s;
    color: #FFF;
    font-weight: bold;
    border: 2px solid #FFF;
    cursor: pointer;
}

.timeline-image h4 a {
    color: #FFF;
}

.timeline-image h4 a:hover {
    text-decoration: none;
}


/** Life History **/


/** Social Icons **/

.social-icon {
    padding: 5px;
    color: #FFF;
    margin-right: 2px;
}

.social-icon:hover {
    color: inherit;
}

.btn-email {
    background: #C64135;
}

.btn-phone {
    background: #1a91da;
}

.btn-facebook {
    background: #0D8CF1;
}

.btn-twitter {
    background: #1A91DA;
}

.btn-linkedin {
    background: #0077B5;
}

.btn-youtube {
    background: #FF0000;
}

.btn-github {
    background: #FFF;
    color: #14161e;
}

.btn-github:hover {
    color: #14161e;
}

.btn-instagram {
    background: #D82678;
}


/** Social Icons **/


/** Contact Page **/

section#contact form#contactForm .form-group input,
section#contact form#contactForm .form-group textarea {
    padding: .6rem !important;
}

.form-group p {
    line-height: 1rem;
}

.cursor-text {
    cursor: text !important;
}


/** Contact Page **/


/**********************************************************************************
                            Footer
***********************************************************************************/